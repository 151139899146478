import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Merchant_Front_FAT_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'order-';
export default {
  path: '/order',
  name: 'order',
  redirect: {
    name: "".concat(pre, "center")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'center',
    name: "".concat(pre, "center"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '订单管理',
      keepAlive: true
    }),
    component: function component() {
      return import('@/pages/order/center/index');
    }
  }, {
    path: 'points',
    name: "".concat(pre, "points"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '积分订单'
    }),
    component: function component() {
      return import('@/pages/order/points/index');
    }
  }, {
    path: 'shelves',
    name: "".concat(pre, "shelves"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '货架订单',
      keepAlive: true
    }),
    component: function component() {
      return import('@/pages/order/shelves/index');
    }
  }, {
    path: 'ledger',
    name: "".concat(pre, "ledger"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '分账订单'
    }),
    component: function component() {
      return import('@/pages/order/ledger/index');
    }
  }, {
    path: 'reprint',
    name: "".concat(pre, "reprint"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '订单管理'
    }),
    component: function component() {
      return import('@/pages/order/reprint/index');
    }
  }, {
    path: 'chargeback',
    name: "".concat(pre, "chargeback"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '订单管理'
    }),
    component: function component() {
      return import('@/pages/order/chargeback/index');
    }
  }, {
    path: 'buslog',
    name: "".concat(pre, "buslog"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '订单业务日志查看'
    }),
    component: function component() {
      return import('@/pages/order/center/buslog');
    }
  }, {
    path: 'shelvescoupon',
    name: "".concat(pre, "shelvescoupon"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '货架券'
    }),
    component: function component() {
      return import('@/pages/order/shelvescoupon/index');
    }
  }, {
    path: 'jixiangcoupon',
    name: "".concat(pre, "jixiangcoupon"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '优惠明细'
    }),
    component: function component() {
      return import('@/pages/order/jixiangcoupon/index');
    }
  }, {
    path: 'comment',
    name: "".concat(pre, "comment"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '订单评论'
    }),
    component: function component() {
      return import('@/pages/order/comment/index');
    }
  }, {
    path: 'rechargeorder',
    name: "".concat(pre, "rechargeorder"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '储值订单'
    }),
    component: function component() {
      return import('@/pages/order/rechargeorder/index');
    }
  }]
};