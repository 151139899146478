import showConfig from "./showConfig";
var pre = '/managermenu/';
export default {
  path: 'managermenu',
  title: '商户管理',
  header: 'platform',
  icon: 'md-speedometer',
  showConfig: showConfig.compute,
  auth: ['m-admin'],
  children: [{
    path: "".concat(pre, "merchantList"),
    title: '信息维护',
    auth: ['m-admin']
  }, {
    path: "".concat(pre, "merchant"),
    title: '新增商户',
    auth: ['m-admin'],
    meta: {
      hideInMenu: true
    }
  }]
};