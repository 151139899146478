import showConfig from "./showConfig";
var pre = '/vip/';
export default {
  path: '/vip',
  title: '会员管理',
  header: 'platform',
  icon: 'md-speedometer',
  showConfig: showConfig.compute,
  auth: ['m-admin'],
  children: [{
    path: "".concat(pre, "information"),
    title: '会员信息'
  }, {
    path: "".concat(pre, "grade"),
    title: '会员等级'
  }]
};