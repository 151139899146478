import Power from "@/api/power";
export default {
  install: function install(Vue, options) {
    var powerInfo = false;
    var uid = 0;
    Vue.prototype.PowerInfo = function (userId) {
      return new Promise(function (resolve, reject) {
        if (powerInfo && uid == userId) {
          resolve(powerInfo);
          return;
        }
        Power.Menu({
          ManagerId: userId,
          System: 2
        }).then(function (data) {
          if (data.code == 200) {
            uid = userId;
            powerInfo = data.data;
            resolve(powerInfo);
          }
        });
      });
    };
  }
};