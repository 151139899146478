import request from '@/plugins/request';
import file from '@/plugins/request/file';
export function GetAllQueryOrder(data) {
  return request({
    url: '/product/api/order/getallqueryorder',
    method: 'post',
    data: data
  });
}
export function GetOrderdetail(data) {
  return request({
    url: '/product/api/order/getorderdetail',
    method: 'post',
    data: data
  });
}
export function SalesTodayAllRequest(data) {
  return request({
    url: '/product/api/order/salesTodayAllRequest',
    method: 'post',
    data: data
  });
}
export function SalesTodayTakeawayRequest(data) {
  return request({
    url: '/product/api/order/salesTodayTakeawayRequest',
    method: 'post',
    data: data
  });
}
export function SalesTodayRestaurantRequest(data) {
  return request({
    url: '/product/api/order/salesTodayRestaurantRequest',
    method: 'post',
    data: data
  });
}
export function CancelOrder(data) {
  return request({
    url: '/arch/api/OrderS/CancelOrderByCode',
    method: 'post',
    data: data
  });
}

// 小杨生煎申请退款
export function XyApplyCancelOrder(data) {
  return request({
    url: '/arch/api/OrderS/CanDaoUpdateOrderState',
    method: 'post',
    data: data
  });
}
export function GetOrderTaskNumber(data) {
  return request({
    url: '/product/api/order/GetOrderTaskNumber',
    method: 'post',
    data: data
  });
}

/** 获取积分订单 */
export function GetPointsOrder(data) {
  return request({
    url: '/product/api/order/GetPointsOrder',
    method: 'post',
    data: data
  });
}

/** 获取积分订单明细 */
export function GetPointsOrderDetails(data) {
  return request({
    url: '/product/api/order/GetPointsOrderDetails',
    method: 'post',
    data: data
  });
}

/** 更新积分订单信息 */
export function UpdatePointsOrder(data) {
  return request({
    url: '/product/api/order/UpdatePointsOrder',
    method: 'post',
    data: data
  });
}

/** 商户移除订单 */
export function DelPointsOrder(data) {
  return request({
    url: '/product/api/order/DelPointsOrder',
    method: 'post',
    data: data
  });
}

/** 积分商城退单 */
export function CancelPointsOrder(data) {
  return request({
    url: '/arch/api/orders/CancelPointsOrder',
    method: 'post',
    data: data
  });
}
export function ExportOrders(data) {
  return file({
    url: '/order/api/order/export',
    method: 'post',
    data: data
  });
}
export function ExportOrdersTask(data) {
  return request({
    url: '/order/api/order/ExportTask',
    method: 'post',
    data: data
  });
}
export function GetNoticeCount(data) {
  return request({
    url: '/order/api/order/GetNoticeCount',
    method: 'post',
    data: data
  });
}
export function NoticeRead(data) {
  return request({
    url: '/order/api/order/NoticeRead',
    method: 'post',
    data: data
  });
}
export function GetNoticeList(data) {
  return request({
    url: '/order/api/order/GetNoticeList',
    method: 'post',
    data: data
  });
}
export function GetMerchantOrderList(data) {
  return request({
    url: '/order/api/DouyinShelves/order/GetMerchantOrderList',
    method: 'post',
    data: data
  });
}
export function GetMerchantOrderDetails(data) {
  return request({
    url: '/order/api/DouyinShelves/order/GetMerchantOrderDetails',
    method: 'post',
    data: data
  });
}
export function GetHDLOrderDetailsReport(data) {
  return request({
    url: '/order/api/DouyinShelves/order/GetHDLOrderDetailsReport',
    method: 'post',
    data: data
  });
}
export function GetMerchantThirdChannel(data) {
  return request({
    url: '/product/api/Merchant/GetMerchantThirdChannel',
    method: 'post',
    data: data
  });
}
export function ReplenishmentOrderSubmit(data) {
  return request({
    url: '/product/api/order/ReplenishmentOrderSubmit',
    method: 'post',
    data: data
  });
}
export function GetShelvesOrderByWaimai(data) {
  return request({
    url: '/order/rpc/OrderMapping/GetShelvesOrderByWaimai',
    method: 'post',
    data: data
  });
}
export function GetWaimaiOrderByShelves(data) {
  return request({
    url: '/order/rpc/OrderMapping/GetWaimaiOrderByShelves',
    method: 'post',
    data: data
  });
}
export function GetDiscounts(data) {
  return request({
    url: 'https://applets.hualala.com/order/rpc/OrderMapping/GetDiscounts',
    method: 'post',
    data: data
  });
}
export function AggPaymentError(data) {
  return request({
    url: '/proxy/api/pay/merchantRefund',
    method: 'post',
    data: data
  });
}
export function DouyinShelvesApplyRefund(data) {
  return request({
    url: '/order/api/DouyinShelves/Order/ApplyRefund',
    method: 'post',
    data: data
  });
}

/** BK取消 */
export function BKCancleOrder(data) {
  return request({
    url: '/order/api/BkOrder/BKCancleOrder',
    method: 'post',
    data: data
  });
}

/** BK退款 */
export function BKCustomerRefund(data) {
  return request({
    url: '/order/api/BkOrder/BKCustomerRefund',
    method: 'post',
    data: data
  });
}

/** BK重推 */
export function PushOrderToBkPos(data) {
  return request({
    url: '/order/api/BkOrder/PushOrderToBkPos',
    method: 'post',
    data: data
  });
}

/** 美团重推 */
export function PushOrderToMeiTuanPos(data) {
  return request({
    url: '/proxy/api/MeiTuanOrder/PushOrderToMeiTuanPos',
    method: 'post',
    data: data
  });
}