var pre = '/brandInformation/';
export default {
  path: '/brandInformation',
  title: '品牌信息',
  header: 'platform',
  icon: 'md-speedometer',
  auth: ['m-admin'],
  children: [{
    path: "".concat(pre, "view"),
    title: '品牌查看'
  }]
};