// const pre = '/management/';

// export default{
//     path: '/management',
//     title: '商户信息',
//     header: 'platform',
//     icon: 'md-speedometer',
//     auth: ['m-admin'],
//     children: [
//         {
//             path: `${pre}box`,
//             title: '信息维护'
//         }
//     ]
// }