import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Merchant_Front_FAT_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'commodity-';
export default {
  path: '/commodity',
  name: 'commodity',
  redirect: {
    name: "".concat(pre, "classification")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'classification',
    name: "".concat(pre, "classification"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '商品分类'
    }),
    component: function component() {
      return import('@/pages/commodity/classification/index');
    }
  }, {
    path: 'unit',
    name: "".concat(pre, "unit"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '销售单位'
    }),
    component: function component() {
      return import('@/pages/commodity/unit/index');
    }
  }, {
    path: 'maintenance',
    name: "".concat(pre, "maintenance"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '属性维护'
    }),
    component: function component() {
      return import('@/pages/commodity/maintenance/index');
    }
  }, {
    path: 'stall',
    name: "".concat(pre, "stall"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '档口维护'
    }),
    component: function component() {
      return import('@/pages/commodity/stall/index');
    }
  }, {
    path: 'commodityMain',
    name: "".concat(pre, "commodityMain"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '商品维护'
    }),
    component: function component() {
      return import('@/pages/commodity/commodityMain/index');
    }
  }, {
    path: 'recipe/index',
    name: "".concat(pre, "recipe/index"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '菜谱维护'
    }),
    component: function component() {
      return import('@/pages/commodity/recipe/index');
    }
  }, {
    path: 'productTags',
    name: "".concat(pre, "productTags"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '商品标签'
    }),
    component: function component() {
      return import('@/pages/commodity/productTags');
    }
  }, {
    path: 'editable',
    name: "".concat(pre, "editable"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '新增产品'
    }),
    component: function component() {
      return import('@/pages/commodity/editable/index');
    }
  }, {
    path: 'recipe/edit',
    name: "".concat(pre, "recipe/edit"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '更新菜谱'
    }),
    component: function component() {
      return import('@/pages/commodity/recipe/edit');
    }
  }, {
    path: 'combolist',
    name: "".concat(pre, "combolist"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '产品组成'
    }),
    component: function component() {
      return import('@/pages/commodity/combolist');
    }
  }, {
    path: 'setMealGroup',
    name: "".concat(pre, "setMealGroup"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '套餐组别'
    }),
    component: function component() {
      return import('@/pages/commodity/setMealGroup');
    }
  }, {
    path: 'seqinfo',
    name: "".concat(pre, "seqinfo"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '组成信息'
    }),
    component: function component() {
      return import('@/pages/commodity/seqinfo');
    }
  }, {
    path: 'point',
    name: "".concat(pre, "point"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '积分商品维护'
    }),
    component: function component() {
      return import('@/pages/commodity/point/index');
    }
  }, {
    path: 'productSoldout',
    name: "".concat(pre, "productSoldout"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '门店估清商品'
    }),
    component: function component() {
      return import('@/pages/commodity/productSoldout/productTable');
    }
  }]
};