import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Merchant_Front_FAT_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'brandManagement-';
export default {
  path: '/brandManagement',
  name: 'brandManagement',
  redirect: {
    name: "".concat(pre, "company")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'company',
    name: "".concat(pre, "company"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '公司管理'
    }),
    component: function component() {
      return import('@/pages/brandManagement/company/index');
    }
  }, {
    path: 'brand',
    name: "".concat(pre, "brand"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '品牌管理'
    }),
    component: function component() {
      return import('@/pages/brandManagement/brand/index');
    }
  }, {
    path: 'brand/add',
    name: "".concat(pre, "brand/add"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '品牌管理'
    }),
    component: function component() {
      return import('@/pages/brandManagement/brand/add');
    }
  }, {
    path: 'store',
    name: "".concat(pre, "store"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '门店管理'
    }),
    component: function component() {
      return import('@/pages/brandManagement/store/index');
    }
  }, {
    path: 'store',
    name: "".concat(pre, "store-storeinfo"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '门店信息'
    }),
    component: function component() {
      return import('@/pages/brandManagement/store/storeinfo');
    }
  }, {
    path: 'business',
    name: "".concat(pre, "store-business"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '新增营业区'
    }),
    component: function component() {
      return import('@/pages/brandManagement/store/business');
    }
  },
  // {
  //     path: 'district',
  //     name: `${pre}district`,
  //     meta: {
  //         ...meta,
  //         title: '组别及商圈'
  //     },
  //     component: () => import('@/pages/brandManagement/district/index')
  // },
  // {
  //   path: 'order',
  //   name: `${pre}order`,
  //   meta: {
  //       ...meta,
  //       title: '订单管理'
  //   },
  //   component: () => import('@/pages/brandManagement/order/center')
  // }
  {
    path: 'franchisee',
    name: "".concat(pre, "franchisee"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '加盟商管理'
    }),
    component: function component() {
      return import('@/pages/brandManagement/franchisee/index');
    }
  }]
};