var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("Menu", {
    ref: "menu",
    staticClass: "i-layout-menu-side i-scrollbar-hide",
    class: {
      "i-layout-menu-side-collapse": _vm.menuCollapse
    },
    attrs: {
      theme: _vm.siderTheme,
      accordion: _vm.menuAccordion,
      "active-name": _vm.activePath,
      "open-names": _vm.openNames,
      width: "auto"
    }
  }, [_vm._l(_vm.filterSider, function (item, index) {
    return !_vm.menuCollapse ? [item.children === undefined || !item.children.length ? _c("i-menu-side-item", {
      key: index,
      attrs: {
        menu: item
      }
    }) : _c("i-menu-side-submenu", {
      key: index,
      attrs: {
        menu: item
      }
    })] : [item.children === undefined || !item.children.length ? _c("Tooltip", {
      key: index,
      attrs: {
        content: _vm.tTitle(item.title),
        placement: "right"
      }
    }, [_c("i-menu-side-item", {
      attrs: {
        menu: item,
        "hide-title": ""
      }
    })], 1) : _c("i-menu-side-collapse", {
      key: index,
      attrs: {
        menu: item,
        "top-level": ""
      }
    })];
  })], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };