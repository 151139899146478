import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Merchant_Front_FAT_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'product-';
export default {
  path: '/product',
  name: 'product',
  redirect: {
    name: "".concat(pre, "list")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'new',
    name: "".concat(pre, "new"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '新增菜品'
    }),
    component: function component() {
      return import('@/pages/product/new');
    }
  }, {
    path: 'index',
    name: "".concat(pre, "index"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '新增菜品'
    }),
    component: function component() {
      return import('@/pages/product/editable/index');
    }
  },
  // {
  //   path: 'list',
  //   name: `${pre}list`,
  //   meta: {
  //     ...meta,
  //     title: '产品列表'
  //   },
  //   component: () => import('@/pages/product/list')
  // },
  {
    path: 'combolist',
    name: "".concat(pre, "combolist"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '产品组成'
    }),
    component: function component() {
      return import('@/pages/product/combolist');
    }
  }, {
    path: 'category',
    name: "".concat(pre, "category"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '分类管理'
    }),
    component: function component() {
      return import('@/pages/product/category');
    }
  }, {
    path: 'category-info',
    name: "".concat(pre, "category-info"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '分类管理'
    }),
    component: function component() {
      return import('@/pages/product/category/info');
    }
  }, {
    path: 'comboinfo',
    name: "".concat(pre, "comboinfo"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '套餐组成'
    }),
    component: function component() {
      return import('@/pages/product/comboinfo');
    }
  }, {
    path: 'mapseq',
    name: "".concat(pre, "mapseq"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '套餐组成'
    }),
    component: function component() {
      return import('@/pages/product/productMapSeq');
    }
  }, {
    path: 'seqinfo',
    name: "".concat(pre, "seqinfo"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '组成信息'
    }),
    component: function component() {
      return import('@/pages/product/seqinfo');
    }
  }, {
    path: 'menu',
    name: "".concat(pre, "menu"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '菜谱管理'
    }),
    component: function component() {
      return import('@/pages/product/menu');
    }
  }, {
    path: 'menu-classifylist',
    name: "".concat(pre, "menu-classifylist"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '菜谱管理'
    }),
    component: function component() {
      return import('@/pages/product/menuClassifyList');
    }
  }, {
    path: 'menu-store',
    name: "".concat(pre, "menu-store"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '组成信息'
    }),
    component: function component() {
      return import('@/pages/product/menuStoreMapping');
    }
  }, {
    path: 'menu-info',
    name: "".concat(pre, "menu-info"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '组成信息'
    }),
    component: function component() {
      return import('@/pages/product/menuinfo');
    }
  },
  // {
  //   path: 'menu-edit',
  //   name: `${pre}menu-edit`,
  //   meta: {
  //     ...meta,
  //     title: '编辑'
  //   },
  //   component: () => import('@/pages/product/edit')
  // },
  {
    path: 'menu-stores',
    name: "".concat(pre, "menu-stores"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '应用菜谱门店'
    }),
    component: function component() {
      return import('@/pages/product/menuStores');
    }
  }, {
    path: 'menu-classify-products',
    name: "".concat(pre, "menu-classify-products"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '应用菜谱门店'
    }),
    component: function component() {
      return import('@/pages/product/ClassifyProductList');
    }
  }, {
    path: 'menu-products',
    name: "".concat(pre, "menu-products"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '应用菜谱门店'
    }),
    component: function component() {
      return import('@/pages/product/menuProductList');
    }
  }, {
    path: 'menu-${pre}mapping',
    name: "".concat(pre, "menu-productmapping"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '应用菜谱门店'
    }),
    component: function component() {
      return import('@/pages/product/menuProductMapping');
    }
  }]
};