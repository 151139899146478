import showConfig from "./showConfig";
var pre = '/style/';
export default {
  path: '/style',
  title: '小程序装修',
  header: 'platform',
  icon: 'md-speedometer',
  auth: ['m-admin'],
  showConfig: showConfig.compute,
  children: [{
    path: "".concat(pre, "box"),
    title: '主题配色'
  }, {
    path: "".concat(pre, "exhibition"),
    title: '展示列表'
  }, {
    path: "".concat(pre, "preview"),
    title: '小程序预览'
  }, {
    path: "".concat(pre, "advertising"),
    title: '轮播广告配置'
  }]
};