import request from '@/plugins/request';
var Power = {};
Power.Menu = function (data) {
  return request({
    url: '/user/api/power/menu',
    method: 'post',
    data: data
  });
};
Power.Actions = function (data) {
  return request({
    url: '/user/api/power/actions',
    method: 'post',
    data: data
  });
};
Power.RoleList = function (data) {
  return request({
    url: '/user/api/power/rolelist',
    method: 'post',
    data: data
  });
};
Power.RoleSave = function (data) {
  return request({
    url: '/user/api/power/rolesave',
    method: 'post',
    data: data
  });
};
Power.RoleActionMapping = function (data) {
  return request({
    url: '/user/api/power/RoleActionMapping',
    method: 'post',
    data: data
  });
};
export default Power;