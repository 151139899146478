import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _toConsumableArray from "/var/lib/jenkins/workspace/Merchant_Front_FAT_E1/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.regexp.split";
import _defineProperty from "/var/lib/jenkins/workspace/Merchant_Front_FAT_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { mapState, mapGetters, mapActions } from 'vuex';
import menuSider from '@/menu/sider';
import tTitle from "../mixins/translate-title";
import Setting from '@/setting';
import { getAllSiderMenu } from '@/libs/system';
import { cloneDeep } from 'lodash';
import _random from '@/libs/random_str';
export default {
  name: 'iTabs',
  mixins: [tTitle],
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState('admin/page', ['opened', 'current'])), mapState('admin/layout', ['showTabsIcon', 'tabsFix', 'tabsReload', 'tabsOrder', 'headerFix', 'headerStick', 'isMobile', 'menuCollapse'])), mapGetters('admin/menu', ['hideSider'])), {}, {
    classes: function classes() {
      return {
        'i-layout-tabs-fix': this.tabsFix
      };
    },
    isHeaderStick: function isHeaderStick() {
      return this.hideSider;
    },
    styles: function styles() {
      var style = {};
      if (this.tabsFix && !this.headerFix) {
        style.top = "".concat(64 - this.scrollTop, "px");
      }
      var menuWidth = this.isHeaderStick ? 0 : this.menuCollapse ? 80 : Setting.menuSideWidth;
      if (!this.isMobile && this.tabsFix) {
        style.width = "calc(100% - ".concat(menuWidth, "px)");
        style.left = "".concat(menuWidth, "px");
      }
      return style;
    }
  }),
  data: function data() {
    return {
      // 得到所有侧边菜单，并转为平级，查询图标用
      allSiderMenu: getAllSiderMenu(menuSider),
      scrollTop: 0
    };
  },
  methods: _objectSpread(_objectSpread({}, mapActions('admin/page', ['close', 'closeLeft', 'closeRight', 'closeOther', 'closeAll', 'updateOpened'])), {}, {
    tabLabel: function tabLabel(h, page) {
      var title = h('span', this.tTitle(page.meta.title) || '未命名');
      var slot = [];
      if (this.showTabsIcon) {
        var fullPathWithoutQuery = page.fullPath.indexOf('?') >= 0 ? page.fullPath.split('?')[0] : page.fullPath;
        var currentMenu = this.allSiderMenu.find(function (menu) {
          return menu.path === fullPathWithoutQuery;
        }) || {};
        var icon;
        if (currentMenu.icon) {
          icon = h('Icon', {
            props: {
              type: currentMenu.icon
            }
          });
        } else if (currentMenu.custom) {
          icon = h('Icon', {
            props: {
              custom: currentMenu.custom
            }
          });
        } else if (currentMenu.img) {
          icon = h('img', {
            attrs: {
              src: currentMenu.img
            }
          });
        }
        if (icon) slot.push(icon);
        slot.push(title);
      } else {
        slot.push(title);
      }
      return h('div', {
        class: 'i-layout-tabs-title'
      }, slot);
    },
    handleClickTab: function handleClickTab(tabName) {
      if (tabName === this.current) {
        if (this.tabsReload) {
          this.$emit('on-reload');
        }
      } else {
        var page = this.opened.find(function (page) {
          return page.fullPath === tabName;
        });
        var name = page.name,
          params = page.params,
          query = page.query;
        if (page) this.$router.push({
          name: name,
          params: params,
          query: query
        }, function () {});
      }
    },
    handleClickClose: function handleClickClose(tagName) {
      this.close({
        tagName: tagName
      });
    },
    handleScroll: function handleScroll() {
      if (this.tabsFix && !this.headerFix) {
        var scrollTop = document.body.scrollTop + document.documentElement.scrollTop;
        this.scrollTop = scrollTop > 64 ? 64 : scrollTop;
      }
    },
    handleClose: function handleClose(name) {
      var params = {
        pageSelect: this.current
      };
      switch (name) {
        case 'left':
          this.closeLeft(params);
          break;
        case 'right':
          this.closeRight(params);
          break;
        case 'other':
          this.closeOther(params);
          break;
        case 'all':
          this.closeAll();
          break;
      }
    },
    handleDragDrop: function handleDragDrop(name, newName, a, b) {
      var opened = cloneDeep(this.opened);
      opened.splice.apply(opened, [b, 1].concat(_toConsumableArray(opened.splice(a, 1, opened[b]))));
      this.updateOpened({
        opened: opened
      });
    },
    random: function random() {
      return _random(6);
    }
  }),
  mounted: function mounted() {
    document.addEventListener('scroll', this.handleScroll, {
      passive: true
    });
    this.handleScroll();
  },
  beforeDestroy: function beforeDestroy() {
    document.removeEventListener('scroll', this.handleScroll);
  }
};