import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Merchant_Front_FAT_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'iHeaderSetting',
  data: function data() {
    return {
      visible: false
    };
  },
  computed: _objectSpread({}, mapState('admin/layout', ['siderTheme', 'headerTheme', 'headerStick', 'siderFix', 'headerFix', 'headerHide', 'menuAccordion', 'showSiderCollapse', 'tabs', 'showTabsIcon', 'tabsFix', 'showBreadcrumb', 'showBreadcrumbIcon', 'showReload', 'showI18n', 'showCollapseMenuTitle'])),
  methods: _objectSpread(_objectSpread({}, mapMutations('admin/layout', ['updateLayoutSetting'])), {}, {
    showSetting: function showSetting() {
      this.visible = true;
    },
    handleChangeSetting: function handleChangeSetting(key, value) {
      this.updateLayoutSetting({
        key: key,
        value: value
      });
    }
  })
};