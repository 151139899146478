import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.number.constructor";
import _defineProperty from "/var/lib/jenkins/workspace/Merchant_Front_FAT_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { mapState } from 'vuex';
import { GetNoticeCount, NoticeRead, GetNoticeList } from '@/api/order';
export default {
  name: 'iHeaderNotice',
  data: function data() {
    return {
      badgeProps: {
        offset: [20, 0]
      },
      count: 0,
      messageList: [],
      waitList: [],
      tabcount: {
        undolist: 0,
        dolist: 0
      }
    };
  },
  computed: _objectSpread({}, mapState('admin/layout', ['isMobile'])),
  methods: {
    getData: function getData() {
      var _this = this;
      var id = this.brandInfo.brandId;
      GetNoticeCount({
        brandId: id
      }).then(function (data) {
        if (data.code == 200) {
          _this.count = Number(data.data);
        }
      });
      var types = {
        'ShelvesOrderReport': '货架券核销明细',
        'HDLOrderDetailsReport': '订单分账明细',
        'HDLOrderDiscountReport': '订单券明细',
        'ShelvesOrder': '货架订单明细',
        'Coupon': '用券列表',
        'Activity': '活动列表'
      };
      GetNoticeList({
        brandId: id,
        status: 0
      }).then(function (data) {
        if (data.code == 200) {
          _this.tabcount.undolist = data.data.count;
          for (var i in data.data.notices) {
            var d = data.data.notices[i];
            var type = types[d.messageID];
            if (type == null) type = '订单列表';
            _this.messageList.push({
              title: "【" + type + "】(id:" + d.id + ")",
              read: d.status == 2,
              id: d.id,
              url: d.url,
              time: d.createTime,
              status: d.status,
              content: d.start + "到" + d.end,
              icon: "ios-alarm-outline",
              iconColor: "#2d8cf0"
            });
          }
        }
      });
      GetNoticeList({
        brandId: id,
        status: 1
      }).then(function (data) {
        if (data.code == 200) {
          _this.tabcount.dolist = data.data.count;
          for (var i in data.data.notices) {
            var d = data.data.notices[i];
            var type = types[d.messageID];
            if (type == null) type = '订单列表';
            _this.waitList.push({
              title: "" + type + "(id:" + d.id + ")",
              read: d.status == 1,
              id: d.id,
              url: d.url,
              time: d.createTime,
              status: d.status,
              content: d.start + "到" + d.end,
              icon: d.status == 1 ? "ios-cloud-download" : "ios-cloud-download",
              iconColor: d.status == 1 ? "#ff9900" : "#808695"
            });
          }
        }
      });
    },
    handleLoadMore: function handleLoadMore(s) {
      console.log(s);
    },
    onItemClick: function onItemClick(tab, index) {
      var item = null;
      if (tab.name == "undolist") {
        item = this.messageList[index.index];
      } else {
        item = this.waitList[index.index];
        item.status = 2;
        item.iconColor = "#808695";
        NoticeRead({
          id: item.id
        }).then(function (data) {});
      }
      var result = item.status == 0 ? "任务尚未执行，预计在今天22:00后执行" : "执行结果请从以下地址下载：<a href=\"https://image.streffy.com/" + item.url + "\" target=\"_blank\">https://image.streffy.com/" + item.url + "</a>";
      this.$Modal.info({
        title: item.title,
        content: item.content + "数据导出任务 <br />创建时间：" + item.time + "<br />" + result
      });
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.BrandInfo().then(function (data) {
      _this2.brandInfo = data;
      _this2.current = 1;
      _this2.getData();
    });
  }
};