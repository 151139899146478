var pre = '/merchantImport/';
export default {
  path: '/merchantImport',
  title: '商户进件',
  header: 'home',
  icon: 'md-speedometer',
  auth: ['admin', 'm-admin', 'p-admin'],
  children: [{
    path: "".concat(pre, "incomingList"),
    title: '进件列表',
    auth: ['p-admin', 'admin', 'm-admin']
  }, {
    path: "".concat(pre, "weChatHome"),
    title: '微信',
    auth: ['p-admin', 'admin', 'm-admin']
  }, {
    path: "".concat(pre, "Sweep/sweep"),
    title: '扫呗',
    auth: ['p-admin', 'admin', 'm-admin']
  }]
};