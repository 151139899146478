// 菜单，顶栏
import showConfig from "./modules/showConfig";
export default [{
  path: '/',
  title: '首页',
  icon: 'md-home',
  hideSider: false,
  name: 'home'
}
// {
//     path: '/manager',
//     title: '用户管理',
//     hideSider: false,
//     icon: 'icon iconfont icon-yonghu',
//     name: 'manager'
// },
// {
//     path: '/platform',
//     title: '品牌管理',
//     hideSider: false,
//     icon: 'icon iconfont icon-chanpin',
//     name: 'platform'
// },
// // {
// //     path: '/order',
// //     title: '订单管理',
// //     hideSider: false,
// //     icon: 'icon iconfont icon-dingdanguanli',
// //     name: 'order'
// // },
// {
//     path: '/deskinfo',
//     title: '桌台管理',
//     hideSider: false,
//     icon: 'icon iconfont icon-canzhuo',
//     name: 'deskinfo'
// }
];