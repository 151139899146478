import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/var/lib/jenkins/workspace/Merchant_Front_FAT_E1/node_modules/core-js/modules/es6.array.iterator.js";
import "/var/lib/jenkins/workspace/Merchant_Front_FAT_E1/node_modules/core-js/modules/es6.promise.js";
import "/var/lib/jenkins/workspace/Merchant_Front_FAT_E1/node_modules/core-js/modules/es6.object.assign.js";
import "/var/lib/jenkins/workspace/Merchant_Front_FAT_E1/node_modules/core-js/modules/es7.promise.finally.js";
// Vue
import Vue from 'vue';
import App from "./App";
// 配置
import Setting from "./setting";

// 混合
import mixinApp from '@/mixins/app';

// 插件
import plugins from '@/plugins';
import VueClipboard from 'vue-clipboard2';

// store
import store from '@/store/index';

// iView 和 iView Pro
import ViewUI from 'view-design';
import iViewPro from '@/libs/iview-pro/iview-pro.min.js';
import preview from 'vue-photo-preview';
import 'vue-photo-preview/dist/skin.css';

// 轮播图
import VueAwesomeSwiper from 'vue-awesome-swiper';
import "./assets/css/swiper.css";
// 菜单和路由
import router from "./router";
import menuHeader from '@/menu/header';
import menuSider from '@/menu/sider';
import { frameInRoutes } from '@/router/routes';
import * as filters from '@/libs/filters';
import util from '@/libs/util';

// 多语言
import _i18n from '@/i18n';

// 方法
import { getHeaderName, getMenuSider, getSiderSubmenu } from '@/libs/system';

// 内置组件
import iLink from '@/components/link';
import iFrame from '@/components/frame';
import LoginUser from '@/components/global/LoginUser';
import PowerInfo from '@/components/global/PowerInfo';
import moment from 'moment'; // 导入模块

// 使用样式，修改主题可以在 styles 目录下创建新的主题包并修改 iView 默认的 less 变量
// 参考 https://www.iviewui.com/docs/guide/theme
import "./styles/index.less";
import "./libs/iview-pro/iview-pro.css";
Vue.use(VueAwesomeSwiper);
if (window) window.$t = function (key, value) {
  return _i18n.t(key, value);
};
Vue.use(plugins);
Vue.use(LoginUser);
Vue.use(PowerInfo);
Vue.use(VueClipboard);
Vue.use(preview);
Vue.use(ViewUI, {
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});
Vue.use(iViewPro);
Vue.component('i-link', iLink);
Vue.component('i-frame', iFrame);
moment.locale('zh-cn'); // 设置语言 或 moment.lang('zh-cn');
Vue.prototype.$moment = moment; // 赋值使用

// 全局注册过滤器
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
new Vue({
  mixins: [mixinApp],
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  },
  beforeCreate: function beforeCreate() {
    var _this = this;
    // 全局监听F5，控制刷新频率
    document.onkeydown = function (e) {
      var key = e.key;
      var date = new Date();
      var currentTimeStamp = date.getTime();
      if (key == 'F5') {
        var timestamp = sessionStorage.getItem('f5');
        if (timestamp && currentTimeStamp - timestamp < 1 * 1000) {
          _this.$Notice.success({
            title: '耐心等待，结果马上出来',
            desc: ''
          });
          e.preventDefault();
        } else {
          sessionStorage.setItem('f5', currentTimeStamp);
        }
      }
    };
  },
  created: function created() {
    // 处理路由 得到每一级的路由设置
    this.$store.commit('admin/page/init', frameInRoutes);
    // 设置顶栏菜单
    this.$store.commit('admin/menu/setHeader', menuHeader);
    // 加载用户登录的数据
    this.$store.dispatch('admin/account/load');
    // 初始化全屏监听
    this.$store.dispatch('admin/layout/listenFullscreen');
  },
  watch: {
    // 监听路由 控制侧边栏显示 标记当前顶栏菜单（如需要）
    '$route': function $route(to, from) {
      var _this2 = this;
      var path = to.matched[to.matched.length - 1].path;
      if (!Setting.dynamicSiderMenu) {
        var headerName = getHeaderName(path, menuSider);
        if (headerName === null) {
          path = to.path;
          headerName = getHeaderName(path, menuSider);
        }
        // 在 404 时，是没有 headerName 的
        if (headerName !== null) {
          this.$store.commit('admin/menu/setHeaderName', headerName);
          this.$store.commit('admin/menu/setMenuSider', menuSider);
          var filterMenuSider = getMenuSider(menuSider, headerName);
          this.$store.commit('admin/menu/setSider', filterMenuSider);
          this.$store.commit('admin/menu/setActivePath', to.path);
          var openNames = getSiderSubmenu(path, menuSider);
          this.$store.commit('admin/menu/setOpenNames', openNames);
        }
        this.appRouteChange(to, from);
      } else {
        var _headerName = 'home';
        var data = null;
        var managerId = this.LoginUser().managerID;
        this.PowerInfo(managerId).then(function (data) {
          if (data.nodes != null) {
            if (_this2.LoginUser().tenantId > 0) {
              for (var i = 0; i < data.nodes.length; i++) {
                if (data.nodes[i].path == '/platform/brand') {
                  data.nodes[i].meta = {
                    hideInMenu: true
                  };
                }
              }
            }
            _this2.$store.commit('admin/menu/setHeaderName', _headerName);
            _this2.$store.commit('admin/menu/setMenuSider', data.nodes);
            var _filterMenuSider = getMenuSider(data.nodes, _headerName);
            _this2.$store.commit('admin/menu/setSider', _filterMenuSider);
            _this2.$store.commit('admin/menu/setActivePath', to.path);
            var _openNames = getSiderSubmenu(path, data.nodes);
            _this2.$store.commit('admin/menu/setOpenNames', _openNames);
          }
        });
        this.appRouteChange(to, from);
      }
    }
  }
}).$mount('#app');