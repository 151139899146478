export default {
  name: 'i-copyright',
  data: function data() {
    return {
      links: [{
        title: '官网',
        key: '官网',
        href: 'https://www.streffy.com/',
        blankTarget: true
      }
      // {
      //     title: '社区',
      //     key: '社区',
      //     href: 'https://dev.iviewui.com',
      //     blankTarget: true
      // },
      // {
      //     title: '专业版',
      //     key: '专业版',
      //     href: 'https://pro.iviewui.com',
      //     blankTarget: true
      // }
      ],
      copyright: 'Copyright © 2020 斯慧（上海）信息科技有限公司'
    };
  }
};