import showConfig from "./showConfig";
var pre = '/userAuthority/';
export default {
  path: '/userAuthority',
  title: '用户及权限',
  header: 'platform',
  showConfig: showConfig.compute,
  icon: 'md-speedometer',
  auth: ['m-admin'],
  children: [{
    path: "".concat(pre, "box"),
    title: '用户管理'
  }, {
    path: "".concat(pre, "box2"),
    title: '角色管理'
  }]
};