import showConfig from "./showConfig";
var pre = '/marketing/';
export default {
  path: '/marketing',
  title: '营销管理',
  header: 'platform',
  icon: 'md-speedometer',
  showConfig: showConfig.compute,
  auth: ['m-admin'],
  children: [{
    path: "".concat(pre, "rule"),
    title: '营销规则'
  }, {
    path: "".concat(pre, "plan"),
    title: '营销计划'
  }, {
    path: "".concat(pre, "activelist"),
    title: '抽奖活动'
  }, {
    path: "".concat(pre, "new"),
    title: '满减',
    meta: {
      hideInMenu: true
    }
  }, {
    path: "".concat(pre, "type"),
    title: '营销计划',
    meta: {
      hideInMenu: true
    }
  }, {
    path: "".concat(pre, "fullGift"),
    title: '满赠',
    meta: {
      hideInMenu: true
    }
  }, {
    path: "".concat(pre, "recharge"),
    title: '储值赠送配置',
    meta: {
      hideInMenu: true
    }
  }]
};