import "core-js/modules/es6.regexp.replace";
import style from "./modules/style";
import dashboard from "./modules/dashboard";
import manager from "./modules/manager";
import platform from "./modules/platform";
import brand from "./modules/brand";
import BasicLayout from '@/layouts/basic-layout';
import baseinfo from "./modules/baseinfo";
import product from "./modules/product";
import order from "./modules/order";
import deskinfo from "./modules/deskinfo";
import setting from "./modules/setting";
import dataview from "./modules/dataview";
import management from "./modules/management";
import brandmanagement from "./modules/brandmanagement";
import vip from "./modules/vip";
import marketing from "./modules/marketing";
import userAuthority from "./modules/userAuthority";
import brandInformation from "./modules/brandInformation";
import commodity from "./modules/commodity";
import managermenu from "./modules/managermenu";
import merchantImport from "./modules/merchantImport";
import configuration from "./modules/configuration";
import douyinshelves from "./modules/douyinshelves";
import douyinshelvesFusion from "./modules/douyinshelvesFusion";
import douyinshelvesCatering from "./modules/douyinshelvesCatering";
import xiaohongshu from "./modules/xiaohongshu";
import shipinghao from "./modules/shipinghao";

/**
 * 在主框架内显示
 */

var frameIn = [{
  path: '/',
  redirect: {
    name: 'platform-brand'
  },
  component: BasicLayout,
  children: [{
    path: 'index',
    name: 'index',
    redirect: {
      name: 'dashboard-console'
    }
  }, {
    path: 'log',
    name: 'log',
    meta: {
      title: '前端日志',
      auth: true
    },
    component: function component() {
      return import('@/pages/system/log');
    }
  },
  // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: {
      beforeRouteEnter: function beforeRouteEnter(to, from, next) {
        next(function (instance) {
          return instance.$router.replace(from.fullPath);
        });
      },
      render: function render(h) {
        return h();
      }
    }
  },
  // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: {
      beforeRouteEnter: function beforeRouteEnter(to, from, next) {
        next(function (instance) {
          return instance.$router.replace(JSON.parse(from.params.route));
        });
      },
      render: function render(h) {
        return h();
      }
    }
  }]
}, dashboard, manager, brand, managermenu, platform, baseinfo, product, order, deskinfo, style, setting, dataview, management, brandmanagement, vip, marketing, userAuthority, brandInformation, commodity, merchantImport, configuration, douyinshelves, douyinshelvesFusion, douyinshelvesCatering, xiaohongshu, shipinghao];

/**
 * 在主框架之外显示
 */

var frameOut = [
// 登录
{
  path: '/login',
  name: 'login',
  meta: {
    title: '$t:page.login.title'
  },
  component: function component() {
    return import('@/pages/account/login');
  }
},
// 注册
{
  path: '/register',
  name: 'register',
  meta: {
    title: '$t:page.register.title'
  },
  component: function component() {
    return import('@/pages/account/register');
  }
},
// 注册结果
{
  path: '/register/result',
  name: 'register-result',
  meta: {
    auth: true,
    title: '注册结果'
  },
  component: function component() {
    return import('@/pages/account/register/result');
  }
}];

/**
 * 错误页面
 */

var errorPage = [{
  path: '/403',
  name: '403',
  meta: {
    title: '403'
  },
  component: function component() {
    return import('@/pages/system/error/403');
  }
}, {
  path: '/500',
  name: '500',
  meta: {
    title: '500'
  },
  component: function component() {
    return import('@/pages/system/error/500');
  }
}, {
  path: '*',
  name: '404',
  meta: {
    title: '404'
  },
  component: function component() {
    return import('@/pages/system/error/404');
  }
}];

// 导出需要显示菜单的
export var frameInRoutes = frameIn;

// 重新组织后导出
export default [].concat(frameIn, frameOut, errorPage);