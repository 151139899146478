var pre = '/brandManagement/';
export default {
  path: '/brandManagement',
  title: '经营主体',
  header: 'platform',
  icon: 'md-speedometer',
  auth: ['m-admin'],
  children: [
  // {
  //     path: `${pre}company`,
  //     title: '公司信息'
  // },
  {
    path: "".concat(pre, "brand"),
    title: '公司信息管理'
  }, {
    path: "".concat(pre, "brand/add"),
    title: '新增公司',
    meta: {
      hideInMenu: true
    }
  }, {
    path: "".concat(pre, "store"),
    title: '门店管理'
  },
  // {
  //     path: `${pre}order`,
  //     title: '订单管理'
  // },
  {
    path: "".concat(pre, "storeinfo"),
    title: '门店管理',
    meta: {
      hideInMenu: true
    }
  }
  // ,
  // {
  //     path: `${pre}district`,
  //     title: '组别及商圈'
  // }
  , {
    path: "".concat(pre, "franchisee"),
    title: '加盟商管理'
  }]
};