import showConfig from "./showConfig";
var pre = '/order/';
export default {
  path: '/order',
  title: '订单管理',
  header: 'platform',
  icon: 'md-speedometer',
  auth: ['m-admin'],
  showConfig: showConfig.compute,
  children: [{
    path: "".concat(pre, "center"),
    title: '订单查看'
  }, {
    path: "".concat(pre, "center"),
    title: '积分订单'
  }, {
    path: "".concat(pre, "shelves"),
    title: '货架订单'
  }, {
    path: "".concat(pre, "ledger"),
    title: '分账订单'
  }, {
    path: "".concat(pre, "reprint"),
    title: '重印订单',
    meta: {
      hideInMenu: true
    }
  }, {
    path: "".concat(pre, "chargeback"),
    title: '退单',
    meta: {
      hideInMenu: true
    }
  }, {
    path: "".concat(pre, "buslog"),
    title: '订单业务日志查看'
  }, {
    path: "".concat(pre, "shelvescoupon"),
    title: '货架券'
  }, {
    path: "".concat(pre, "rechargeorder"),
    title: '储值订单'
  }]
};