var pre = '/platform/';
export default {
  path: '/platform',
  title: '渠道管理',
  header: 'platform',
  icon: 'icon iconfont icon-channel',
  auth: ['m-admin'],
  children: [{
    path: "".concat(pre, "channel"),
    title: '渠道信息'
  }, {
    path: "".concat(pre, "merchant"),
    title: '商户管理'
  }, {
    path: "".concat(pre, "brand"),
    title: '品牌管理'
  }]
};