var pre = '/setting/';
export default {
  path: '/setting',
  title: '设置中心',
  header: 'home',
  custom: 'icon iconfont icon-shezhi',
  auth: ['m-admin'],
  children: [{
    path: "".concat(pre, "account"),
    title: '设置中心'
  }]
};