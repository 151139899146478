import request from '@/plugins/request';
var Merchant = {};
Merchant.List = function (data) {
  return request({
    url: '/product/api/merchant/list',
    method: 'post',
    data: data
  });
};
Merchant.BrandList = function (data) {
  return request({
    url: '/product/api/merchant/BrandList',
    method: 'post',
    data: data
  });
};
Merchant.Merchantinfo = function (data) {
  return request({
    url: '/product/api/merchant/merchantinfo',
    method: 'post',
    data: data
  });
};
Merchant.UpdateMerchantinfo = function (data) {
  return request({
    url: '/product/api/merchant/updatemerchantinfo',
    method: 'post',
    data: data
  });
};

/** 商户数据保存 */
Merchant.MerchantSave = function (data) {
  return request({
    url: '/product/api/Merchant/TenementSave',
    method: 'post',
    data: data
  });
};
Merchant.TenementSave = function (data) {
  return request({
    url: '/product/api/Merchant/MerchantSave',
    method: 'post',
    data: data,
    timeout: 119000
  });
};
Merchant.RuleList = function (data) {
  return request({
    url: '/product/api/Merchant/RuleList',
    method: 'post',
    data: data
  });
};
Merchant.AptitudeList = function (data) {
  return request({
    url: '/product/api/Merchant/AptitudeList',
    method: 'post',
    data: data
  });
};
Merchant.ServiceList = function (data) {
  return request({
    url: '/product/api/Merchant/ServiceList',
    method: 'post',
    data: data
  });
};
Merchant.TenantList = function (data) {
  return request({
    url: '/product/api/Merchant/TenantList',
    method: 'post',
    data: data
  });
};

/** 获取省市区 */
Merchant.AreaList = function (data) {
  return request({
    url: '/product/api/Merchant/AreaList',
    method: 'post',
    data: data
  });
};

/** 获取申请单号查询申请状态 */
Merchant.GetImportBusinessCode = function (data) {
  return request({
    url: '/product/api/Merchant/GetImportBusinessCode',
    method: 'post',
    data: data
  });
};

/** 获取开户行 */
Merchant.BranchList = function (data) {
  return request({
    url: '/product/api/Merchant/BranchList',
    method: 'post',
    data: data
  });
};

/** 获取银行简称 */
Merchant.BranchShortList = function (data) {
  return request({
    url: '/product/api/Merchant/BranchShortList',
    method: 'post',
    data: data
  });
};

/** 获取加盟商信息 */
Merchant.FranchiseeAppList = function (data) {
  return request({
    url: '/product/api/Merchant/FranchiseeAppList',
    method: 'post',
    data: data
  });
};
export default Merchant;