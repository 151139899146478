import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Merchant_Front_FAT_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'marketing-';
export default {
  path: '/marketing',
  name: 'marketing',
  redirect: {
    name: "".concat(pre, "rule")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'rule',
    name: "".concat(pre, "rule"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '营销规则'
    }),
    component: function component() {
      return import('@/pages/marketing/rule/index');
    }
  }, {
    path: 'plan',
    name: "".concat(pre, "plan"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '营销计划'
    }),
    component: function component() {
      return import('@/pages/marketing/plan/index');
    }
  }, {
    path: 'new',
    name: "".concat(pre, "new"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '营销计划'
    }),
    component: function component() {
      return import('@/pages/marketing/plan/new');
    }
  }, {
    path: 'type',
    name: "".concat(pre, "type"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '满减'
    }),
    component: function component() {
      return import('@/pages/marketing/plan/type');
    }
  }, {
    path: 'fullGift',
    name: "".concat(pre, "fullGift"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '满赠'
    }),
    component: function component() {
      return import('@/pages/marketing/plan/fullGift');
    }
  }, {
    path: 'increasePrice',
    name: "".concat(pre, "increasePrice"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '加价购'
    }),
    component: function component() {
      return import('@/pages/marketing/plan/increasePrice');
    }
  }, {
    path: 'distribution',
    name: "".concat(pre, "distribution"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '免配送'
    }),
    component: function component() {
      return import('@/pages/marketing/plan/distribution');
    }
  }, {
    path: 'fullDiscount',
    name: "".concat(pre, "fullDiscount"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '满折活动'
    }),
    component: function component() {
      return import('@/pages/marketing/plan/fullDiscount');
    }
  }, {
    path: 'secondDiscount',
    name: "".concat(pre, "secondDiscount"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '第二件n折活动'
    }),
    component: function component() {
      return import('@/pages/marketing/plan/secondDiscount');
    }
  }, {
    path: 'openScreen',
    name: "".concat(pre, "openScreen"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '开屏广告设置'
    }),
    component: function component() {
      return import('@/pages/marketing/plan/openScreen');
    }
  }, {
    path: 'storeGift',
    name: "".concat(pre, "storeGift"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '进店有礼'
    }),
    component: function component() {
      return import('@/pages/marketing/plan/storeGift');
    }
  }, {
    path: 'payBill',
    name: "".concat(pre, "payBill"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '买单侠'
    }),
    component: function component() {
      return import('@/pages/marketing/plan/payBill');
    }
  }, {
    path: 'coupon',
    name: "".concat(pre, "coupon"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '优惠券'
    }),
    component: function component() {
      return import('@/pages/marketing/coupon/index');
    }
  }, {
    path: 'coupon/edit',
    name: "".concat(pre, "coupon-edit"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '优惠券编辑'
    }),
    component: function component() {
      return import('@/pages/marketing/coupon/edit');
    }
  }, {
    path: 'activelist',
    name: "".concat(pre, "activelist"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '抽奖活动'
    }),
    component: function component() {
      return import('@/pages/marketing/activelist/index');
    }
  }, {
    path: 'activelist/save',
    name: "".concat(pre, "activelist-save"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '抽奖活动'
    }),
    component: function component() {
      return import('@/pages/marketing/activelist/save');
    }
  }, {
    path: 'actives',
    name: "".concat(pre, "activelist-actives"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '抽奖活动'
    }),
    component: function component() {
      return import('@/pages/marketing/activelist/actives');
    }
  }, {
    path: 'records',
    name: "".concat(pre, "activelist-records"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '抽奖活动'
    }),
    component: function component() {
      return import('@/pages/marketing/activelist/records');
    }
  }, {
    path: 'largemarket',
    name: "".concat(pre, "largemarket"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '数据大盘'
    }),
    component: function component() {
      return import('@/pages/marketing/largemarket/index');
    }
  }, {
    path: 'jidian/index',
    name: "".concat(pre, "jidian-index"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '集点活动'
    }),
    component: function component() {
      return import('@/pages/marketing/jidian/index');
    }
  }, {
    path: 'jidian/save',
    name: "".concat(pre, "jidian-save"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '集点设置'
    }),
    component: function component() {
      return import('@/pages/marketing/jidian/save');
    }
  }, {
    path: 'jidian/orders',
    name: "".concat(pre, "jidian-orders"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '集点订单'
    }),
    component: function component() {
      return import('@/pages/marketing/jidian/records');
    }
  }, {
    path: 'jidian/users',
    name: "".concat(pre, "jidian-users"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '集点订单'
    }),
    component: function component() {
      return import('@/pages/marketing/jidian/userrecords');
    }
  }, {
    path: 'likes',
    name: "".concat(pre, "likes-index"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '活动首页'
    }),
    component: function component() {
      return import('@/pages/marketing/likes/index');
    }
  }, {
    path: 'likes/save',
    name: "".concat(pre, "likes-save"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '活动设置'
    }),
    component: function component() {
      return import('@/pages/marketing/likes/save');
    }
  }, {
    path: 'likes/apply',
    name: "".concat(pre, "likes-apply"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '审核列表'
    }),
    component: function component() {
      return import('@/pages/marketing/likes/apply');
    }
  }, {
    path: 'likes/records',
    name: "".concat(pre, "likes-records"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '参与列表'
    }),
    component: function component() {
      return import('@/pages/marketing/likes/records');
    }
  }, {
    path: 'wxwork',
    name: "".concat(pre, "wxwork-index"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '门店企微码'
    }),
    component: function component() {
      return import('@/pages/marketing/wxwork/index');
    }
  }, {
    path: 'recharge',
    name: "".concat(pre, "recharge"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '储值赠送配置'
    }),
    component: function component() {
      return import('@/pages/marketing/recharge/index');
    }
  }, {
    path: 'recharge/edit',
    name: "".concat(pre, "recharge-edit"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '储值赠礼编辑'
    }),
    component: function component() {
      return import('@/pages/marketing/recharge/edit');
    }
  }]
};