import request from '@/plugins/request';
export function AccountLogin(data) {
  return request({
    url: '/user/api/manager/login',
    method: 'post',
    data: data
  });
}
export function AccountRegister(data) {
  return request({
    url: '/api/register',
    method: 'post',
    data: data
  });
}
var Manager = {};
Manager.Query = function (data) {
  return request({
    url: '/user/api/manager/query',
    method: 'post',
    data: data
  });
};
Manager.Info = function (data) {
  return request({
    url: '/user/api/manager/info',
    method: 'post',
    data: data
  });
};
Manager.Save = function (data) {
  return request({
    url: '/user/api/manager/save',
    method: 'post',
    data: data
  });
};
Manager.Update = function (data) {
  return request({
    url: '/user/api/manager/update',
    method: 'post',
    data: data
  });
};
Manager.UpdateStatus = function (data) {
  return request({
    url: '/user/api/manager/UpdateStatus',
    method: 'post',
    data: data
  });
};
Manager.SetUserPoints = function (data) {
  return request({
    url: 'https://gray-applets.hualala.com/user/api/User/SetUserPoints',
    method: 'post',
    data: data
  });
};
Manager.GetUserPoints = function (data) {
  return request({
    url: '/user/api/User/GetUserPoints',
    method: 'post',
    data: data
  });
};
export default Manager;