// import showConfig from './showConfig'
// const pre = '/product/';

// export default {
//     path: '/product',
//     title: '产品管理',
//     header: 'platform',
//     icon: 'md-speedometer',
//     auth: ['m-admin'],
//     showConfig: showConfig.compute,
//     children: [
//         {
//             path: `${pre}index`,
//             title: '新增产品'
//         },
//         {
//             path: `${pre}combolist`,
//             title: '产品组成'
//         },
//         {
//             path: `${pre}category`,
//             title: '分类管理'
//         },
//         {
//             path: `${pre}menu`,
//             title: '菜谱管理'
//         }
//     ]
// }