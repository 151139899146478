import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Merchant_Front_FAT_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'configuration-';
export default {
  path: '/configuration',
  name: 'configuration',
  redirect: {
    name: "".concat(pre, "couponmapper")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'couponmapper',
    name: "".concat(pre, "couponmapper"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '券码转换'
    }),
    component: function component() {
      return import('@/pages/configuration/couponmapper/index.vue');
    }
  }, {
    path: 'douyinpay',
    name: "".concat(pre, "douyinpay"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '抖音支付'
    }),
    component: function component() {
      return import('@/pages/configuration/douyinpay/index.vue');
    }
  }, {
    path: 'basicssys',
    name: "".concat(pre, "basicssys"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '商户基本配置'
    }),
    component: function component() {
      return import('@/pages/configuration/basicssys/index.vue');
    }
  }, {
    path: 'dockingsys',
    name: "".concat(pre, "dockingsys"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '第三方配置'
    }),
    component: function component() {
      return import('@/pages/configuration/dockingsys/index.vue');
    }
  }, {
    path: 'businessswitch',
    name: "".concat(pre, "businessswitch"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '业务开关'
    }),
    component: function component() {
      return import('@/pages/configuration/businessswitch/index.vue');
    }
  }, {
    path: 'storemappingdouyin',
    name: "".concat(pre, "storemappingdouyin"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '抖音门店映射'
    }),
    component: function component() {
      return import('@/pages/configuration/storemappingdouyin/index.vue');
    }
  }, {
    path: 'douyinmini',
    name: "".concat(pre, "douyinmini"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '抖音小程序管理'
    }),
    component: function component() {
      return import('@/pages/configuration/douyinmini/index.vue');
    }
  }, {
    path: 'appletversion',
    name: "".concat(pre, "appletversion"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '小程序版本管理'
    }),
    component: function component() {
      return import('@/pages/configuration/appletversion/index.vue');
    }
  }, {
    path: 'poisync',
    name: "".concat(pre, "poisync"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: 'POI匹配'
    }),
    component: function component() {
      return import('@/pages/configuration/poisync/index.vue');
    }
  }, {
    path: 'moduleconfig',
    name: "".concat(pre, "moduleconfig"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '模块配置列表'
    }),
    component: function component() {
      return import('@/pages/configuration/moduleconfig/index.vue');
    }
  }, {
    path: 'moduleconfig/save',
    name: "".concat(pre, "moduleconfig-save"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '模块配置'
    }),
    component: function component() {
      return import('@/pages/configuration/moduleconfig/save.vue');
    }
  }]
};