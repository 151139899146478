import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Merchant_Front_FAT_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'douyinshelvesFusion-';
export default {
  path: '/douyinshelvesFusion',
  name: 'douyinshelvesFusion',
  redirect: {
    name: "".concat(pre, "cpskickback")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'cpskickback',
    name: "".concat(pre, "cpskickback"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: 'CPS佣金'
    }),
    component: function component() {
      return import('@/pages/douyinshelvesFusion/cpskickback/index.vue');
    }
  }, {
    path: 'shopsync',
    name: "".concat(pre, "shopsync"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '门店同步'
    }),
    component: function component() {
      return import('@/pages/douyinshelvesFusion/shopsync/index.vue');
    }
  }, {
    path: 'goodsync',
    name: "".concat(pre, "goodsync"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '商品同步'
    }),
    component: function component() {
      return import('@/pages/douyinshelvesFusion/goodsync/index.vue');
    }
  }, {
    path: 'goodsadd',
    name: "".concat(pre, "goodsadd"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '抖音 - 新建商品'
    }),
    component: function component() {
      return import('@/pages/douyinshelvesFusion/goodsync/edit.vue');
    }
  }, {
    path: 'goodsview',
    name: "".concat(pre, "goodsview"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '抖音 - 查看商品'
    }),
    component: function component() {
      return import('@/pages/douyinshelvesFusion/goodsync/edit.vue');
    }
  }, {
    path: 'goodscopy',
    name: "".concat(pre, "goodscopy"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '抖音 - 复制商品'
    }),
    component: function component() {
      return import('@/pages/douyinshelvesFusion/goodsync/edit.vue');
    }
  }, {
    path: 'goodsedit',
    name: "".concat(pre, "goodsedit"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '抖音 - 编辑商品'
    }),
    component: function component() {
      return import('@/pages/douyinshelvesFusion/goodsync/edit.vue');
    }
  }, {
    path: 'liveshelves',
    name: "".concat(pre, "liveshelves"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '直播货架'
    }),
    component: function component() {
      return import('@/pages/douyinshelvesFusion/liveshelves/index.vue');
    }
  }, {
    path: 'liveshelvesedit',
    name: "".concat(pre, "liveshelvesedit"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '新建货架'
    }),
    component: function component() {
      return import('@/pages/douyinshelvesFusion/liveshelves/edit.vue');
    }
  }, {
    path: 'consudetails',
    name: "".concat(pre, "consudetails"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '核销明细'
    }),
    component: function component() {
      return import('@/pages/douyinshelvesFusion/consudetails/index.vue');
    }
  }, {
    path: 'billDetails',
    name: "".concat(pre, "billDetails"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '分账明细'
    }),
    component: function component() {
      return import('@/pages/douyinshelvesFusion/billDetails/index.vue');
    }
  }, {
    path: 'coupons',
    name: "".concat(pre, "coupons"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '抖音劵管理'
    }),
    component: function component() {
      return import('@/pages/douyinshelvesFusion/coupons/index.vue');
    }
  }]
};