import _defineProperty from "/var/lib/jenkins/workspace/Merchant_Front_FAT_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import showConfig from "./showConfig";
var pre = '/commodity/';
export default _defineProperty(_defineProperty({
  path: '/commodity/commodityMain/index',
  title: '商品管理',
  header: 'platform',
  icon: 'md-speedometer',
  showConfig: showConfig.compute,
  auth: ['m-admin']
}, "showConfig", showConfig.compute), "children", [{
  path: "".concat(pre, "classification"),
  title: '商品分类'
}, {
  path: "".concat(pre, "unit"),
  title: '销售单位'
}, {
  path: "".concat(pre, "maintenance"),
  title: '属性维护'
}, {
  path: "".concat(pre, "stall"),
  title: '档口维护'
}, {
  path: "".concat(pre, "commodityMain"),
  title: '商品信息'
}, {
  path: "".concat(pre, "recipe/index"),
  title: '菜谱维护'
}, {
  path: "".concat(pre, "editable"),
  title: '新增产品',
  meta: {
    hideInMenu: true
  }
}, {
  path: "".concat(pre, "combolist"),
  title: '产品组成'
}, {
  path: "".concat(pre, "setMealGroup"),
  title: '套餐组别'
}, {
  path: "".concat(pre, "productTags"),
  title: '商品标签'
}, {
  path: "".concat(pre, "recipe/edit"),
  title: '更新菜谱',
  meta: {
    hideInMenu: true
  }
}, {
  path: "".concat(pre, "point"),
  title: '积分商品维护'
}, {
  path: "".concat(pre, "productSoldout"),
  title: '门店估清商品'
}]);