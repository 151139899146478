
// const pre = '/baseinfo/';

// export default {
//     path: '/baseinfo',
//     title: '基础信息',
//     header: 'platform',
//     icon: 'md-speedometer',
//     auth: ['m-admin'],
//     children: [
//         {
//             path: `${pre}store`,
//             title: '门店管理'
//         },
//         {
//             path: `${pre}storeinfo`,
//             title: '门店管理',
//             meta: {
//                 hideInMenu: true
//             }
//         }
//     ]
// }