var pre = '/dataView/';
export default {
  path: '/dataView',
  title: '数据查看',
  header: 'platform',
  icon: 'md-speedometer',
  auth: ['m-admin'],
  children: [{
    path: "".concat(pre, "viewData"),
    title: '数据查看'
  }, {
    path: "".concat(pre, "report"),
    title: '报表查询'
  }, {
    path: "".concat(pre, "userMember"),
    title: '会员列表'
  }, {
    path: "".concat(pre, "orderPointDetail"),
    title: '订单积分列表'
  }, {
    path: "".concat(pre, "kdpBuriedData"),
    title: 'Kdp渠道数据'
  }, {
    path: "".concat(pre, "joinChannel"),
    title: '入会渠道数据'
  }, {
    path: "".concat(pre, "joinChannelQT"),
    title: '入会渠道(蜻蜓)'
  }, {
    path: "".concat(pre, "joinChannelDy"),
    title: '入会渠道(抖音)'
  }, {
    path: "".concat(pre, "joinChannelType"),
    title: '入会渠道类型'
  }]
};