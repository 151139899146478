var pre = '/manager/';
export default {
  path: '/manager',
  title: '用户管理',
  header: 'manager',
  icon: 'icon iconfont icon-yonghu',
  auth: ['p-admin', 'p-user', 'admin'],
  children: [{
    path: "".concat(pre, "platformList"),
    title: '渠道管理员列表',
    auth: ['p-admin', 'p-user', 'm-admin', 'admin']
  }, {
    path: "".concat(pre, "targetList"),
    title: '渠道商管理',
    auth: ['p-admin']
  }, {
    path: "".concat(pre, "merchantList"),
    title: '信息维护',
    auth: ['p-user']
  }, {
    path: "".concat(pre, "merchantList"),
    title: '门店用户'
  }, {
    path: "".concat(pre, "merchant"),
    title: '新增商户',
    auth: ['p-admin']
  }
  //   {
  //     path: `${pre}org`,
  //     title: '组织结构'
  // }
  ]
};