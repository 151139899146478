var pre = '/douyinshelves/';
export default {
  path: '/douyinshelves',
  title: '抖音货架',
  header: 'platform',
  icon: 'md-speedometer',
  auth: ['m-admin'],
  children: [{
    path: "".concat(pre, "cpskickback"),
    title: 'CPS佣金'
  }, {
    path: "".concat(pre, "shopsync"),
    title: '门店同步'
  }, {
    path: "".concat(pre, "goodsync"),
    title: '商品同步'
  }, {
    path: "".concat(pre, "liveshelves"),
    title: '直播货架'
  }, {
    path: "".concat(pre, "district"),
    title: '组别及商圈'
  }, {
    path: "".concat(pre, "consudetails"),
    title: '核销明细'
  }, {
    path: "".concat(pre, "coupons"),
    title: '抖音劵管理'
  }]
};