var pre = '/deskinfo/';
export default {
  path: '/deskinfo',
  title: '桌台管理',
  header: 'deskinfo',
  icon: 'icon iconfont icon-canzhuo',
  auth: ['m-admin'],
  children: [{
    path: "".concat(pre, "center"),
    title: '桌台管理'
  }, {
    path: "".concat(pre, "areainfo"),
    title: '区域管理'
  }]
};