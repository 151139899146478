import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Merchant_Front_FAT_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import Basiclayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'merchantImport-';
export default {
  path: '/merchantImport',
  name: 'merchantImport',
  redirect: {
    name: "".concat(pre, "incomingList")
  },
  meta: meta,
  component: Basiclayout,
  children: [{
    path: 'incomingList',
    name: "".concat(pre, "incomingList"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '进件列表'
    }),
    component: function component() {
      return import('@/pages/merchantImport/incomingList');
    }
  }, {
    path: 'weChatHome',
    name: "".concat(pre, "weChatHome"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '微信'
    }),
    component: function component() {
      return import('@/pages/merchantImport/weChatHome');
    }
  }, {
    path: 'WeChat',
    name: "".concat(pre, "WeChat"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '微信新增'
    }),
    component: function component() {
      return import('@/pages/merchantImport/WeChat');
    }
  }, {
    path: 'merchant',
    name: "".concat(pre, "merchant"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '企业类型'
    }),
    component: function component() {
      return import('@/pages/merchantImport/merchant');
    }
  }, {
    path: 'subjectInformation',
    name: "".concat(pre, "subjectInformation"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '主体信息'
    }),
    component: function component() {
      return import('@/pages/merchantImport/subjectInformation');
    }
  }, {
    path: 'businessInformation',
    name: "".concat(pre, "businessInformation"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '经营信息'
    }),
    component: function component() {
      return import('@/pages/merchantImport/businessInformation');
    }
  }, {
    path: 'settlement',
    name: "".concat(pre, "settlement"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '结算规则'
    }),
    component: function component() {
      return import('@/pages/merchantImport/settlement');
    }
  }, {
    path: 'calculationAccount',
    name: "".concat(pre, "calculationAccount"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '结算账户'
    }),
    component: function component() {
      return import('@/pages/merchantImport/calculationAccount');
    }
  }, {
    path: 'administrator',
    name: "".concat(pre, "administrator"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '超级管理员'
    }),
    component: function component() {
      return import('@/pages/merchantImport/administrator');
    }
  }, {
    path: 'chainOperation',
    name: "".concat(pre, "chainOperation"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '连锁经营'
    }),
    component: function component() {
      return import('@/pages/merchantImport/chainOperation');
    }
  }, {
    path: 'smallWeChat/merchant',
    name: "".concat(pre, "smallWeChat/merchant"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '小微'
    }),
    component: function component() {
      return import('@/pages/merchantImport/smallWeChat/merchant');
    }
  }, {
    path: 'smallWeChat/subjectInformation',
    name: "".concat(pre, "smallWeChat/subjectInformation"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '小微主体信息'
    }),
    component: function component() {
      return import('@/pages/merchantImport/smallWeChat/subjectInformation');
    }
  }, {
    path: 'smallWeChat/businessInformation',
    name: "".concat(pre, "smallWeChat/businessInformation"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '小微经营信息'
    }),
    component: function component() {
      return import('@/pages/merchantImport/smallWeChat/businessInformation');
    }
  }, {
    path: 'smallWeChat/settlement',
    name: "".concat(pre, "smallWeChat/settlement"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '小微结算规则'
    }),
    component: function component() {
      return import('@/pages/merchantImport/smallWeChat/settlement');
    }
  }, {
    path: 'smallWeChat/calculationAccount',
    name: "".concat(pre, "smallWeChat/calculationAccount"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '小微结算账户'
    }),
    component: function component() {
      return import('@/pages/merchantImport/smallWeChat/calculationAccount');
    }
  }, {
    path: 'smallWeChat/administrator',
    name: "".concat(pre, "smallWeChat/administrator"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '小微超级管理员'
    }),
    component: function component() {
      return import('@/pages/merchantImport/smallWeChat/administrator');
    }
  }, {
    path: 'smallWeChat/chainOperation',
    name: "".concat(pre, "smallWeChat/chainOperation"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '连锁经营'
    }),
    component: function component() {
      return import('@/pages/merchantImport/smallWeChat/chainOperation');
    }
  }, {
    path: 'IndividualBusinesses/merchant',
    name: "".concat(pre, "IndividualBusinesses/merchant"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '个体工商户'
    }),
    component: function component() {
      return import('@/pages/merchantImport/IndividualBusinesses/merchant');
    }
  }, {
    path: 'IndividualBusinesses/subjectInformation',
    name: "".concat(pre, "IndividualBusinesses/subjectInformation"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '个体工商户主体信息'
    }),
    component: function component() {
      return import('@/pages/merchantImport/IndividualBusinesses/subjectInformation');
    }
  }, {
    path: 'IndividualBusinesses/businessInformation',
    name: "".concat(pre, "IndividualBusinesses/businessInformation"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '个体工商户经营信息'
    }),
    component: function component() {
      return import('@/pages/merchantImport/IndividualBusinesses/businessInformation');
    }
  }, {
    path: 'IndividualBusinesses/settlement',
    name: "".concat(pre, "IndividualBusinesses/settlement"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '个体工商户结算规则'
    }),
    component: function component() {
      return import('@/pages/merchantImport/IndividualBusinesses/settlement');
    }
  }, {
    path: 'IndividualBusinesses/calculationAccount',
    name: "".concat(pre, "IndividualBusinesses/calculationAccount"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '个体工商户结算账户'
    }),
    component: function component() {
      return import('@/pages/merchantImport/IndividualBusinesses/calculationAccount');
    }
  }, {
    path: 'IndividualBusinesses/administrator',
    name: "".concat(pre, "IndividualBusinesses/administrator"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '个体工商户超级管理员'
    }),
    component: function component() {
      return import('@/pages/merchantImport/IndividualBusinesses/administrator');
    }
  }, {
    path: 'IndividualBusinesses/calculationAccount',
    name: "".concat(pre, "IndividualBusinesses/calculationAccount"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '个体工商户结算账户'
    }),
    component: function component() {
      return import('@/pages/merchantImport/IndividualBusinesses/calculationAccount');
    }
  }, {
    path: 'IndividualBusinesses/chainOperation',
    name: "".concat(pre, "IndividualBusinesses/chainOperation"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '连锁经营'
    }),
    component: function component() {
      return import('@/pages/merchantImport/IndividualBusinesses/chainOperation');
    }
  }, {
    path: 'Sweep/sweep',
    name: "".concat(pre, "Sweep/sweep"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '扫呗'
    }),
    component: function component() {
      return import('@/pages/merchantImport/Sweep/sweep');
    }
  }, {
    path: 'Sweep/settlement',
    name: "".concat(pre, "Sweep/settlement"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '扫呗结算信息'
    }),
    component: function component() {
      return import('@/pages/merchantImport/Sweep/settlement');
    }
  }, {
    path: 'Sweep/merchantPhotos',
    name: "".concat(pre, "Sweep/merchantPhotos"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '扫呗商户照片'
    }),
    component: function component() {
      return import('@/pages/merchantImport/Sweep/merchantPhotos');
    }
  }, {
    path: 'miniapp/index',
    name: "".concat(pre, "miniapp/index"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '小程序进件用户'
    }),
    component: function component() {
      return import('@/pages/merchantImport/miniapp/index');
    }
  }]
};