// 菜单，侧边栏
// import dashboard from './modules/dashboard';
import platform from "./modules/platform";
import setting from "./modules/setting";
import style from "./modules/style";
import dataview from "./modules/dataview";
import management from "./modules/management";
import brandmanagement from "./modules/brandmanagement";
import vip from "./modules/vip";
import brandInformation from "./modules/brandInformation";
import marketing from "./modules/marketing";
import commodity from "./modules/commodity";
import order from "./modules/order";
import douyinshelves from "./modules/douyinshelves";
import managermenu from "./modules/managermenu";
import merchantImport from "./modules/merchantImport";

// 系统
import log from "./modules/log";
import manager from "./modules/manager";
import brand from "./modules/brand";
import baseinfo from "./modules/baseinfo";
import product from "./modules/product";
import deskinfo from "./modules/deskinfo";
import userAuthority from "./modules/userAuthority";
export default [manager, brand,
// platform,
dataview, managermenu, brandInformation, merchantImport, brandmanagement, commodity, order, style, deskinfo, baseinfo, product, setting, log, management, vip, marketing, userAuthority, managermenu, userAuthority, douyinshelves];