import "core-js/modules/es6.number.constructor";
import util from '@/libs/util';
import { pathInit } from '@/store/modules/admin/modules/db';
import Merchant from '@/api/merchant';
export default {
  install: function install(Vue, options) {
    var brand = false;
    Vue.prototype.LoginUser = function () {
      return util.db.get(pathInit({
        dbName: 'sys',
        path: 'user.info',
        user: true,
        defaultValue: {}
      })).value();
    };
    Vue.prototype.UninstallLoginUser = function () {
      brand = false;
    };
    Vue.prototype.BrandInfo = function () {
      return new Promise(function (resolve, reject) {
        if (brand) {
          resolve(brand);
          return;
        }
        Merchant.BrandList({
          MerChantId: Vue.prototype.LoginUser().merchantId,
          BrandId: Number(util.cookies.get('brand'))
        }).then(function (data) {
          if (data.code == 200 && data.data.count == 1) {
            brand = data.data.details[0];
            resolve(brand);
          }
        });
      });
    };
  }
};